*,
::after,
::before {
	box-sizing: border-box;
}

html {
	font-size: 100%;
	overflow-y: overlay;
}

#root {
	overflow-x: clip;
}

/* variables */
:root {
	/* hsl primary */
	--hue: 165.3;
	--sat: 77.8%;

	/* primary */
	--primary-100: hsl(var(--hue), var(--sat), 90%);
	--primary-200: hsl(var(--hue), var(--sat), 80%);
	--primary-300: hsl(var(--hue), var(--sat), 70%);
	--primary-400: hsl(var(--hue), var(--sat), 60%);
	--primary-500: hsl(var(--hue), var(--sat), 50%);
	--primary-600: hsl(var(--hue), var(--sat), 40%);
	--primary-700: hsl(var(--hue), var(--sat), 30%);
	--primary-800: hsl(var(--hue), var(--sat), 20%);
	--primary-900: hsl(var(--hue), var(--sat), 10%);

	/* secondary */
	--secondary-100: hsl(calc(var(--hue) + 180), var(--sat), 90%);
	--secondary-200: hsl(calc(var(--hue) + 180), var(--sat), 80%);
	--secondary-300: hsl(calc(var(--hue) + 180), var(--sat), 70%);
	--secondary-400: hsl(calc(var(--hue) + 180), var(--sat), 60%);
	--secondary-500: hsl(calc(var(--hue) + 180), var(--sat), 50%);
	--secondary-600: hsl(calc(var(--hue) + 180), var(--sat), 40%);
	--secondary-700: hsl(calc(var(--hue) + 180), var(--sat), 30%);
	--secondary-800: hsl(calc(var(--hue) + 180), var(--sat), 20%);
	--secondary-900: hsl(calc(var(--hue) + 180), var(--sat), 10%);

	/* grey */
	--grey-50: #f8fafc;
	--grey-100: #f1f5f9;
	--grey-200: #e2e8f0;
	--grey-300: #cbd5e1;
	--grey-400: #94a3b8;
	--grey-500: #64748b;
	--grey-600: #475569;
	--grey-700: #334155;
	--grey-800: #1e293b;
	--grey-900: #0f172a;

	/* colors */
	--black: #222;
	--white: #fff;
	--red-light: #f8d7da;
	--red-medium: #f07272;
	--red-dark: #a70000;
	--green-light: #d1e7dd;
	--green-dark: #0f5132;
	--orange-light: #ffeac1;
	--orange-medium: #f0a472;
	--orange-dark: #ffc000;
	--blue-light: #d3e6ff;
	--blue-medium: #6699ff;
	--blue-dark: #4b69ff;
	--purple: #d467ff;
	--green-musics: #176b56;
	--red-blood: #bd2828;

	/* colles */
	--barometre: #e43800;
	--arreplegats: #15a884;
	--pataquers: #f26e00;
	--passerells: #c99fd0;
    --ganapies: #257ba2;
    --marracos: #979db5;
    --trempats: #a48970;
    --xoriguers: #12c3f4;
    --emboirats-1: #ff0000;
    --emboirats-2: #c80036;
    --bergants: #9dd237;
    --engrescats: #ffdd00;
    --penjats: #f80000;
    --grillats: #cd157a;
    --llunatics: #21277c;
    --mineuetts: #000000;
    --mangoners-1: #d6b1ff;
    --mangoners-2: #efa467;
	--gambirots: #c6d6f1;

	/* joc castells */
	--game-clearBlue: #8fb1ec;
	--game-lightBlue: #2857aa;
	--game-mediumBlue: #1b243c;
	--game-darkBlue: #121c32;
	--game-darkerBlue: #0e1528;
	--game-cream: #ffe1c9;
	--game-lightGray: #6a6a6a;
	--game-darkGray: #2b2b2b;
	--game-carregat: #ffe599;

	--game-top-bar-height: 2.6rem;
	--game-sub-bar-height: 1.3rem;
	--game-back-btn-height: 2.5rem;

	/* fonts  */
	--headingFont: 'Roboto', sans-serif;
	--bodyFont: 'Montserrat', sans-serif;
	--smallText: 0.7em;

	/* vars */
	--backgroundColor: var(--grey-50);
	--textColor: var(--grey-900);
	--borderRadius: 0.25rem;
	--letterSpacing: 1px;
	--transition: 0.3s ease-in-out all;
	--fixed-width: 600px;
	--overlay: rgba(0, 0, 0, 0.4);
	--page-width: 80vw;
	--mobile-width: 95vw;
	--navbar-height: 5rem;
	--footer-height: 10rem;

	/* box shadow*/
	--shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	--shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
	0 2px 4px -1px rgba(0, 0, 0, 0.06);
	--shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
	0 4px 6px -2px rgba(0, 0, 0, 0.05);
	--shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
	0 10px 10px -5px rgba(0, 0, 0, 0.04);
	--shadow-5: 0 0 8px 0 rgba(0, 0, 0, 0.05),
	0 2px 8px 0 rgba(0, 0, 0, 0.08);
	--shadow-6: inset 0 -3px 0 0 var(--primary-400);
	--shadow-7: 0 0 8px 0 rgba(0, 0, 0, 0.6);
	--shadow-8: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
}

body {
	background: var(--backgroundColor);
	font-family: var(--bodyFont);
	font-weight: 300;
	line-height: 1.75;
	color: var(--textColor);
}

p {
	margin-top: 0;
	margin-bottom: 1.5rem;
}

h1,
h2,
h3,
h4,
h5 {
	margin: 0;
	margin-bottom: 1.38rem;
	font-family: var(--headingFont);
	font-weight: 400;
	line-height: 1.3;
	letter-spacing: var(--letterSpacing);
}

h1 {
	margin-top: 0;
	font-size: 3.052rem;
}

h2 {
	font-size: 2.441rem;
}

h3 {
	font-size: 1.953rem;
}

h4 {
	font-size: 1.563rem;
}

h5 {
	font-size: 1.25rem;
}

small,
.text-small {
	font-size: var(--smallText);
}

a {
	text-decoration: none;
}
ul {
	list-style-type: none;
	padding: 0;
}

.img {
	width: 100%;
	display: block;
	object-fit: cover;
}

/* buttons */
.btn {
	cursor: pointer;
	color: var(--white);
	background: var(--primary-600);
	border: transparent;
	border-radius: var(--borderRadius);
	letter-spacing: var(--letterSpacing);
	padding: 0.375rem 0.75rem;
	box-shadow: var(--shadow-1);
	transition: var(--transition);
	text-transform: capitalize;
	display: inline-block;
	user-select: none;
}
.btn:hover {
	background: var(--primary-700);
	box-shadow: var(--shadow-3);
}
.btn-hipster {
	color: var(--primary-500);
	background: var(--primary-200);
}
.btn-hipster:hover {
	color: var(--primary-200);
	background: var(--primary-700);
}
.btn-block {
	width: 100%;
}

/* alerts */
.alert {
	padding: 0.375rem 0.75rem;
	margin-bottom: 1rem;
	border-color: transparent;
	border-radius: var(--borderRadius);
}

.alert-danger {
	color: var(--red-dark);
	background: var(--red-light);
}
.alert-success {
	color: var(--green-dark);
	background: var(--green-light);
}

/* form */
.form {
	width: 90vw;
	max-width: var(--fixed-width);
	background: var(--white);
	border-radius: var(--borderRadius);
	box-shadow: var(--shadow-2);
	padding: 2rem 2.5rem;
	margin: 3rem auto;
}
.form-label {
	display: block;
	font-size: var(--smallText);
	margin-bottom: 0.5rem;
	text-transform: capitalize;
	letter-spacing: var(--letterSpacing);
}
.form-input,
.form-textarea {
	width: 100%;
	padding: 0.375rem 0.75rem;
	border-radius: var(--borderRadius);
	background: var(--backgroundColor);
	border: 1px solid var(--grey-200);
}

.form-row {
	margin-bottom: 1rem;
}

.form-textarea {
	height: 7rem;
}
::placeholder {
	font-family: inherit;
	color: var(--grey-400);
}
.form-alert {
	color: var(--red-dark);
	letter-spacing: var(--letterSpacing);
	text-transform: capitalize;
}

input,
textarea {
	outline: none;
	border-radius: var(--borderRadius);
	border: 1px solid var(--grey-400);
	padding: 5px;
}
input[type="date"] {
	appearance: none;
	background-color: var(--white);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 0s ease-in-out 9999999s;
}

/* alert */
@keyframes spinner {
	to {
		transform: rotate(360deg);
	}
}

.loading {
	width: 6rem;
	height: 6rem;
	border: 5px solid var(--grey-300);
	border-radius: 50%;
	border-top-color: var(--primary-600);
	animation: spinner 0.6s linear infinite;
	margin: 0 auto;
}
.game-loading {
	margin-top: 1rem;
	border-top-color: var(--game-lightBlue);
}

/* title */
.title {
	text-align: center;
}

.title-underline {
	background: var(--primary-500);
	width: 7rem;
	height: 0.25rem;
	margin: 0 auto;
	margin-top: -1rem;
}

/*
=============== 
Navbar
===============
*/

.navbar {
	position: sticky;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--primary-600);
	z-index: 99999;
	height: var(--navbar-height);
}

.nav-center {
	width: var(--page-width);
	display: flex;
	align-items: center;
}

.nav-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-right: 2rem;
}

.nav-logo {
	display: flex;
	align-items: center;
}

.nav-header img {
	margin-right: 0.7rem;
	height: 3rem;
	user-select: none;
}

.header-name {
	color: var(--white);
	font-family: 'Roboto';
	font-weight: 300;
	font-size: 1.2rem;
	margin: 0;
	height: 3rem;
	white-space: nowrap;
	user-select: none;
}
.header-name span {
	font-weight: 700;
} 

.nav-btn i {
	font-size: 1.25rem;
	padding-top: 1px;
	padding-left: 1.5px;
}

#nav-links {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	height: var(--navbar-height);
}

.nav-btn {
	display: none;
	user-select: none;
}

.nav-link {
	text-align: center;
	height: 100%;
	display: flex;
	align-items: center;
	font-size: 1rem;
	letter-spacing: var(--letterSpacing);
	transition: var(--transition);
	border-top: 3px solid transparent;
	border-bottom: 3px solid transparent;
	user-select: none;
}
/* TODO: merge FireFox:has() */
.nav-link:hover{
	border-bottom: 3px solid var(--white);
}
.nav-link:has(a.active) {
	border-bottom: 3px solid var(--white);
}

.nav-link span img {
	width: 10px;
	margin-left: 7px;
	margin-bottom: 2px;
}

.nav-link a {
	color: var(--black);
}
.nav-link:hover > a,
.nav-link a.active {
	color: var(--white);
}

.nav-link > a,
.nav-link > span {
	color: var(--black);
	display: block;
	height: 100%;
	width: 100%;
	padding: 0 1.25rem;
	line-height: var(--navbar-height);
}

.sub-menus {
	display: none;
	position: absolute;
	background-color: var(--grey-100);
	top: var(--navbar-height);
	padding: 1rem;
}
.nav-link:hover .sub-menus {
	display: flex;
	flex-wrap: wrap;
	gap: 3rem 0;
}

.sub-menu-title {
	font-size: 0.85rem;
	margin-bottom: 0.9rem;
	text-align: left;
}

.sub-menu {
	margin-right: 30px;
}
.sub-menu:last-of-type {
	margin-right: 0;
}

.sub-menu li {
	text-align: left;
	font-size: 0.8rem;
	white-space: nowrap;
}
.sub-menu a:hover,
.sub-menu a.active {
	color: var(--primary-600);
}

/* TODO: merge FireFox:has() */
.join-btn {
	margin-left: 1.5rem;
	padding: 0.2rem 0;
	background-color: var(--primary-700);
	height: auto;
	border-bottom: 5px solid var(--primary-800);
	transition: none;
}
.join-btn:has(a.active) {
	margin-left: 1.5rem;
	padding: 0.2rem 0;
	background-color: var(--primary-700);
	height: auto;
	border-bottom: 5px solid var(--primary-800);
	transition: none;
}
.join-btn:hover {
	border-top: 5px solid var(--primary-600);
	border-bottom: none !important;
}

.join-btn a {
	display: unset;
	line-height: unset;
	color: var(--white) !important;
}

@media screen and (max-width: 1250px) {
	.nav-center {
		width: var(--mobile-width);
	}
}

@media screen and (max-width: 1050px) {
	.nav-btn {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		height: 3rem;
		width: 100%;
	}
	.nav-btn button {
		height: 100%;
		aspect-ratio: 1;
		background-color: var(--primary-600);
		border: none;
		cursor: pointer;
	}
	.nav-btn button:focus {
		outline: none;
	}

	.nav-btn img {
		width: 100%;
		max-width: none;
		filter: invert(24%) sepia(9%) saturate(6202%) hue-rotate(124deg) brightness(101%) contrast(91%);
	}

	#nav-links {
		display: block;
		transform: translateX(-100vw);
		height: auto;
		max-height: calc(100vh - var(--navbar-height));
		overflow: clip scroll;
		position: absolute;
		top: var(--navbar-height);
		right: 0;
		left: 0;
		background-color: var(--white);
	}
	#nav-links.show {
		transform: none;
	}

	.nav-link {
		display: flex;
		flex-direction: column;
		padding: 0;
		width: 100%;
		border: none !important;
	}
	.nav-link > span:hover,
	.nav-link:hover > a,
	.nav-link a.active {
		color: var(--primary-600);
	}
	.nav-link > span:hover img {
		filter: invert(46%) sepia(99%) saturate(330%) hue-rotate(115deg) brightness(102%) contrast(101%);
	}

	.nav-link > a,
	.nav-link > span {
		line-height: unset;
		height: 100%;
		width: 100%;
		height: 3rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.nav-link span {
		cursor: pointer;
	}

	.sub-menus {
		top: unset;
		background-color: unset;
		padding: 1rem 1rem 0;
		width: 100%;
		position: relative;
	}
	.nav-link:hover .sub-menus {
		display: unset;
	}
	.nav-link:hover .sub-menus:not(.active) {
		display: none;
	}
	.sub-menus.active {
		display: block;
	}

	.sub-menu {
		margin-bottom: 2rem;
	}
	.sub-menu:last-of-type {
		margin-bottom: 0;
	}

	.sub-menu li a {
		display: block;
	}

	.join-btn {
		margin: 0 !important;
		border: none !important;
		transition: var(--transition);
	}
	.join-btn:hover {
		background-color: var(--primary-600) !important;
	}

	.join-btn a {
		padding: 0.8rem 0 !important;
		height: 100%;
		width: 100%;
		margin: unset;
	}
}

/*
=============== 
Footer
===============
*/

.page-footer {
	height: var(--footer-height);
	text-align: center;
	background: var(--black);
	color: var(--white);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	user-select: none;
}

.social-media {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 12rem;
}
.social-media .icon {
	display: flex;
	justify-self: center;
	align-items: center;
	width: 1rem;
	height: 1rem;
}
.social-media .icon img {
	filter: invert(1);
	max-height: 100%;
	max-width: 100%;
}

.twitter:hover,
.twitter-x:hover {
	filter: invert(57%) sepia(38%) saturate(1592%) hue-rotate(169deg) brightness(116%) contrast(90%);
}
.instagram:hover {
	filter: invert(70%) sepia(20%) saturate(6318%) hue-rotate(299deg) brightness(87%) contrast(86%);
}
.youtube:hover {
	filter: invert(82%) sepia(95%) saturate(7086%) hue-rotate(360deg) brightness(107%) contrast(119%);
}
.twitch:hover {
	filter: invert(28%) sepia(10%) saturate(5397%) hue-rotate(227deg) brightness(101%) contrast(95%);
}
.tiktok:hover {
	filter: invert(72%) sepia(100%) saturate(7497%) hue-rotate(335deg) brightness(98%) contrast(108%);
}
.facebook:hover {
	filter: invert(67%) sepia(99%) saturate(497%) hue-rotate(185deg) brightness(90%) contrast(81%);
}
.mail:hover {
	filter: invert(88%) sepia(12%) saturate(748%) hue-rotate(72deg) brightness(95%) contrast(85%);
}
.phone:hover {
	filter: invert(88%) sepia(12%) saturate(748%) hue-rotate(72deg) brightness(95%) contrast(85%);
}

.email {
  text-align: center;
  margin: 1rem 0 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  user-select: text;
}

.email-text {
  color: var(--white);
  font-size: 0.8rem;
}

.copy-btn {
  background: none;
  border: none;
  padding: 0;
  width: 0.9rem;
  height: 0.9rem;
  cursor: pointer;
  opacity: 0.6;
  transition: var(--transition);
}

.copy-btn:hover {
  opacity: 1;
}

.copy-btn.copied {
  opacity: 1;
  cursor: default;
}

.copy-btn img {
  filter: invert(1);
  width: 100%;
  height: 100%;
  transition: filter var(--transition);
}

.universities {
	display: flex;
	flex-direction: row;
	margin: 0.5rem auto 0;
}

.universities img {
	width: 150px;
	object-fit: contain;
	margin-right: 2rem;
}
.universities img:last-of-type {
	margin-right: 0;
}

/*
=============== 
Page
===============
*/

.page {
	min-height: calc(100vh - (var(--navbar-height) + var(--footer-height)));
	padding-bottom: 2rem;
}
.page:empty {
	display: none;
}

.page-full {
	height: 100vh;
	padding: 0;
	position: relative;
	overflow: hidden;
}

section {
	width: var(--page-width);
	margin: 0 auto;
	padding: 1.5rem 0;
}

h4 {
	color: black;
	font-family: 'Roboto';
	font-weight: 500;
	font-size: 1.5rem;
}

@media screen and (max-width: 1250px) {
	section {
		width: var(--mobile-width);
		padding-top: 0.5rem;
	}
}

/*
=============== 
Home
===============
*/

.home-buttons {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.welcome-image {
	height: calc(100vh - 5rem);
	background-position: center 15%;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100vw;
	position: relative;
}

.overlay {
	background-color: rgba(21, 168, 132, 0.5);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
}

.content {
	position: absolute;
	top: 25%;
	left: calc((100vw - var(--page-width)) / 2);
	color: var(--white);
	user-select: none;
	z-index: 3;
}
.content * {
	margin: 0;
	font-family: 'Montserrat';
}

@media screen and (max-width: 1250px) {
	.content {
		left: calc((100vw - var(--mobile-width)) / 2);
	}
}

.content h1 {
	font-size: 10vw;
	font-weight: 700;
	line-height: 0.8;
}

.content h3 {
	font-size: 3.5vw;
	font-weight: 700;
	margin-top: 10px;
	margin-bottom: 3rem;
}

.hero-btn {
	border: 1px solid var(--white);
	color: var(--white);
	padding: 15px 20px;
	font-weight: 500;
	transition: var(--transition);
}
.hero-btn:hover {
	color: var(--primary-600);
	background-color: rgba(255, 255, 255, 0.6);
}

section h4 {
	font-size: 1.3rem;
	margin-bottom: 1rem;
}

.floating-titles {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.floating-titles * {
	width: fit-content;
}

.floating-titles a {
	font-size: 1rem;
	color: var(--black);
}
.floating-titles a:hover {
	color: var(--primary-600);
	text-decoration: underline;
}

.top-gallery {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 10px;
}

@media screen and (max-width: 800px) {
	.top-gallery {
		grid-template-columns: repeat(2, 1fr);
	}

	.floating-titles {
		flex-direction: column;
		margin-bottom: 1rem;
	}
	.floating-titles h4 {
		margin-bottom: 0;
	}
}

@media screen and (max-width: 300px) {
	.top-gallery {
		grid-template-columns: repeat(1, 1fr);
	}
}

.quotes {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 50px 10px;
}

@media screen and (max-width: 1000px) {
	.quotes {
		grid-template-columns: repeat(1, 1fr);
	}
}

/*
=============== 
Castell Card
===============
*/

.castell-card {
	width: 100%;
	aspect-ratio: 0.6;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	overflow: hidden;
}

.castell-link {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: var(--primary-600);
	padding: 10%;
	transform: translateX(-100%);
	transition: var(--transition);
}

.castell-link p {
	font-family: 'Montserrat';
	font-size: 1.8rem;
	text-align: center;
	color: var(--white);
	line-height: 1.3;
	margin: 0;
}

.castell-link a {
	color: var(--black);
	font-size: 1.1rem;
	margin-top: 1.5rem;
	background-color: var(--white);
	padding: 2px 8px;
	opacity: 0.85;
	user-select: none;
}
.castell-link a:hover {
	opacity: 1;
}

.castell-card:hover .castell-link {
	transform: none;
}

/*
=============== 
Error 404
===============
*/

.error-page {
	padding-top: 5rem;
	text-align: center;
}

.error-page h1 {
	font-size: 9rem;
	margin: 0;
	font-family: 'Share Tech Mono';
}

.error-page h3 {
	margin-bottom: 0;
	font-family: 'Share Tech Mono';
}

/*
=============== 
Castell Top
===============
*/

.top-img {
	max-width: 40%;
	max-height: 50vh;
	float: left;
	margin: 0 20px 10px 0;
	user-select: none;
}

@media screen and (max-width: 800px) {
	.top-img {
		max-width: unset;
		max-height: unset;
		width: 100%;
		float: none;
		margin: 0;
		margin-bottom: 10px;
	}
}

/*
=============== 
Qui som?
===============
*/

.dictionary-entry span {
	font-style: italic;
}

section h3 span {
	font-weight: 600;
	text-transform: uppercase;
	color: var(--primary-600);
}

section p a {
	color: var(--primary-600);
	transition: var(--transition);
}
section p a:hover {
	color: var(--secondary-500);
}

.image-divider {
	width: 100vw;
	height: 25vh;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.read-more {
	display: flex;
	justify-content: center;
	margin: 2.5rem auto 0;
	user-select: none;
}

.read-more a {
	background-color: var(--primary-600);
	color: var(--white);
	padding: 10px 25px;
	margin: 0 auto;
	transition: var(--transition);
	border-radius: var(--borderRadius);
	box-shadow: var(--shadow-1);
}
.read-more a:hover {
	background-color: var(--primary-700);
	box-shadow: var(--shadow-3);
}

/*
=============== 
Història
===============
*/

.historia h5 {
	margin-bottom: 0.5rem;
	text-transform: uppercase;
	color: var(--primary-600);
}

section p:last-of-type {
	margin-bottom: 0;
}

/*
=============== 
Assajos
===============
*/

.rehearsal h3 {
	margin-bottom: 0.5rem;
}

.rehearsal iframe {
	border: 0;
	width: 100%;
	aspect-ratio: 1.5;
	max-width: 800px;
	max-height: 400px;
	margin: 1rem auto;
	display: block;
	user-select: none;
}

/*
=============== 
Llista de CdC
===============
*/

.people-gallery {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 40px 15px;
}

@media screen and (max-width: 1000px) {
	.people-gallery {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 600px) {
	.people-gallery {
		grid-template-columns: repeat(1, 1fr);
	}
}

/*
=============== 
Person Card
===============
*/

.person-card {
	width: 100%;
}

.person-img {
	width: 100%;
	aspect-ratio: 1.5;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.person-card h4 {
	margin: 0.5rem 0;
}

.person-card span {
	font-style: italic;
	font-size: 0.85rem;
}

.person-card h6 {
	font-size: 0.8rem;
	margin: 0 0 1rem;
}

.person-card p {
	font-size: 0.75rem;
	margin-bottom: 0.5rem;
}

/*
=============== 
Quote
===============
*/

.quote p {
	text-align: center;
	margin: 0;
	font-size: 1.3rem;
	padding: 0 2rem;
}

.quote h4 {
	color: var(--primary-600);
	text-align: center;
	margin: 1.2rem 0 0;
	font-size: 1.2rem;
}

@media screen and (max-width: 1000px) {
	.quote p {
		font-size: 1.2rem;
	}

	.quote p::before,
	.quote p::after {
		font-size: 1.6rem;
	}

	.quote h4 {
		font-size: 1.3rem;
	}
}

/*
=============== 
Resum històric
===============
*/

.resum-historic h4 {
	margin-bottom: 0.5rem;
}

.historic-filter-container {
	margin-bottom: 2rem;
}

.resum-wrap-wrap {
	margin-bottom: 2rem;
}
.resum-wrap-wrap:last-of-type {
	margin-bottom: 0;
}

.resum-wrap {
	display: grid;
	grid-template-columns: repeat(8, 1fr);
	gap: 30px 15px;
}

@media screen and (max-width: 1500px) {
	.resum-wrap {
		grid-template-columns: repeat(6, 1fr);
	}
}

@media screen and (max-width: 1000px) {
	.resum-wrap {
		grid-template-columns: repeat(5, 1fr);
	}
}

@media screen and (max-width: 800px) {
	.resum-wrap {
		grid-template-columns: repeat(4, 1fr);
	}
}

@media screen and (max-width: 600px) {
	.resum-wrap {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media screen and (max-width: 500px) {
	.resum-wrap {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 300px) {
	.resum-wrap {
		grid-template-columns: repeat(1, 1fr);
	}
}

/*
=============== 
Resum Card
===============
*/

.resum-card {
	background-color: transparent;
	perspective: 1000px;
	user-select: none;
}
.resum-card:not(.locked-card) {
	cursor: pointer;
}

.locked-card {
	cursor: not-allowed;
}

.resum-card-inner {
	transition: var(--transition);
	transform-style: preserve-3d;
}
.resum-card:hover .resum-card-inner {
	transform: rotateY(180deg);
}
.locked-card:hover .resum-card-inner {
	transform: none;
}

.resum-card-front {
	position: relative;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.resum-card-back {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	display: flex;
}

.resum-card-front,
.resum-card-back {
	text-align: center;
	background-color: var(--primary-600);
	padding: 15px 0 8px;
}

.resum-card-back {
	transform: rotateY(180deg);
	background-color: var(--primary-500);
	padding: 0;
	display: flex;
	align-items: center;
	text-align: center;
}

.resum-card-front h4 {
	display: block;
	width: fit-content;
	margin: 0 auto;
	border-bottom: 2px solid var(--white);
	padding: 0 7px;
	color: var(--white);
	font-size: 1.3rem;
}

.resum-card-front h6 {
	color: var(--white);
	font-size: 1.5rem;
	margin: 0;
}

.resum-card-front h6 span {
	font-size: 1.1rem;
}

.resum-card-back a {
	width: 100%;
	height: 100%;
	color: var(--primary-800);
	display: flex;
	align-items: center;
	text-align: center;
}

.resum-card-back a span {
	width: 100%;
}

/*
=============== 
Llista de diades
===============
*/

.llista-diades {
	margin-top: 2rem;
	width: 100%;
}

.llista-diades .filters {
	display: grid;
	gap: 10px;
	grid-template-columns: repeat(4, 1fr);
	user-select: none;
}

.llista-diades .filters .container {
	display: flex;
	flex-direction: column;
}

.llista-diades .filters input,
.llista-diades .filters select {
	border: 1px solid var(--grey-400);
	background-color: var(--grey-200);
	color: var(--black);
	padding: 5px 10px;
}

.select-arrow,
select {
	width: 100%;
}

.dblarrow {
	margin-left: -20px;
	display: inline-block;
	pointer-events: none;
}

.dblarrow b {
	width: 0; 
	height: 0; 
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 5px solid black;
	display: block;
	margin-bottom: 3px;
}

.dblarrow i {
	width: 0; 
	height: 0; 
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid black;
	display: block;
	margin-bottom: -1px;
}

.llista-diades .filter-btn {
	width: 100%;
	margin: 1rem 0 2rem;
	padding: 10px;
	border: 0;
	background-color: var(--primary-600);
	color: var(--white);
	cursor: pointer;
	opacity: 0.85;
	user-select: none;
}
.llista-diades .filter-btn:hover {
	opacity: 1;
}

.llista-diades h6 {
	font-size: 1rem;
	font-weight: 300;
	color: var(--primary-600);
	margin: 0;
	user-select: none;
}

.llista-diades .error {
	color: #ff6b6b !important;
}

.llista-diades .hidden {
	display: none;
}

.diades-table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;
}

.diades-table th,
.diades-table td {
	padding: 5px;
}

.diades-table thead th {
	background-color: var(--primary-700);
	position: sticky;
	top: var(--navbar-height);
	border-top: 3px solid var(--backgroundColor);
	color: #ffffff;
	user-select: none;
}

.diades-table tbody tr td:nth-child(1) {
	text-align: center;
}

.diades-table tbody tr.stripe {
	background-color: var(--primary-200);
}

@media screen and (max-width: 800px) {
	.llista-diades .filters {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 600px) {
	.llista-diades .filters {
		grid-template-columns: repeat(1, 1fr);
	}
}

@media screen and (max-width: 800px) {
	.diades-table {
		font-size: 0.8rem;
	}
}

/*
=============== 
Millors diades
===============
*/

.best-diades {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;
	margin-top: 1rem;
}

.best-diades th,
.best-diades td {
	padding: 5px;
}

.best-diades thead th {
	background-color: var(--primary-700);
	position: sticky;
	top: var(--navbar-height);
	border-top: 3px solid var(--backgroundColor);
	color: #ffffff;
	z-index: 1;
	user-select: none;
}


.best-diades tbody tr td:nth-child(2) {
	font-size: 0.8rem;
}
.best-diades tbody tr td:nth-child(1),
.best-diades tbody tr td:nth-child(2) {
	text-align: right;
	padding: 0 0 0 5px;
}
.best-diades tbody tr td:nth-child(3) {
	padding: 0;
}
.best-diades tbody tr td:nth-child(3),
.best-diades tbody tr td:nth-child(7) {
	text-align: center;
}

.best-diades tbody tr:nth-child(even) {
	background-color: var(--primary-200);
}

.this-season {
	height: 0.9rem;
	filter: invert(16%) sepia(58%) saturate(4997%) hue-rotate(333deg) brightness(85%) contrast(95%);
}

.castell-count {
	font-weight: 700;
}

@media screen and (max-width: 800px) {
	.best-diades {
		font-size: 0.7rem;
	}

	.this-season {
		height: 0.5rem;
	}

	.best-diades tbody tr td:nth-child(2) {
		font-size: 0.6rem;
		padding: 0 5px;
	}
	.best-diades thead tr th:nth-child(5),
	.best-diades tbody tr td:nth-child(5) {
		display: none;
	}
}

/*
=============== 
Vídeos
===============
*/

#videos-wrap {
	display: grid;
	gap: 10px;
	grid-template-columns: repeat(4, 1fr);
	margin-top: 2rem;
	user-select: none;
}

#videos-wrap iframe {
	border: 0;
	width: 100%;
	aspect-ratio: 16/9;
	display: block;
}

@media screen and (max-width: 1500px) {
	#videos-wrap {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media screen and (max-width: 1000px) {
	#videos-wrap {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 500px) {
	#videos-wrap {
		grid-template-columns: repeat(1, 1fr);
	}
}

/*
=============== 
Junta tècnica
===============
*/

.junta-message {
	margin-top: -1rem;
	color: var(--red-dark);
}

.junta-message span {
	font-weight: 700;
}

.junta-message a {
	color: var(--red-dark);
	text-decoration: underline;
}
.junta-message a:hover {
	color: var(--red-dark);
}

.juntes {
	margin-top: 3rem;
	display: grid;
	gap: 0 50px;
	grid-template-columns: repeat(3, 1fr);
}

.junta-team {
	margin-bottom: 2rem;
}

.junta-team h4 {
	margin-bottom: 0.5rem;
	text-transform: uppercase;
}

.junta-team p {
	margin: 0;
	color: var(--grey-500);
}

.junta-team span {
	font-weight: 700;
}

.junta-team hr {
	margin: 1rem 0;
}

.junta-year-wrap {
	display: flex;
	flex-wrap: wrap;
	gap: 3px;
}

.junta-year {
	background-color: var(--primary-600);
	line-height: 1;
	padding: 3px 5px;
	border-radius: 3px;
	user-select: none;
	color: var(--white);
	text-align: center;
	text-wrap: nowrap;
	opacity: 0.5;
}
.junta-year:not(.false) {
pointer-events: none;
	opacity: 1;
}
.junta-year:hover {
	opacity: 1;
}

@media screen and (max-width: 1000px) {
	.juntes {
		grid-template-columns: repeat(1, 1fr);
	}
}

/*
=============== 
Barra Lliure
===============
*/

.confirmation {
	display: flex;
	align-items: center;
	width: 75%;
	margin: 0 auto 3rem;
}

.confirmation img {
	width: 7rem;
	height: 7rem;
	margin-right: 2rem;
}

.confirmation h3 {
	margin: 0;
}

.barcode-wrap {
	width: 30rem;
	max-width: var(--page-width);
	margin: 0 auto;
}

.barcode {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.bar {
	height: 12rem;
	background-color: var(--black);
}

.barcode-wrap p {
	text-align: center;
}

@media screen and (max-width: 1900px) {
	.confirmation {
		width: 90%;
	}

	.confirmation h3 {
		font-size: 1.8rem;
	}
}

@media screen and (max-width: 1500px) {
	.confirmation img {
		width: 5rem;
		height: 5rem;
		margin-right: 1rem;
	}

	.confirmation h3 {
		font-size: 1.5rem;
	}
}

@media screen and (max-width: 1000px) {
	.confirmation {
		width: 100%;
	}

	.confirmation img {
		width: 4rem;
		height: 4rem;
	}
}

@media screen and (max-width: 880px) {
	.confirmation h3 {
		font-size: 1.2rem;
	}
}

@media screen and (max-width: 725px) {
	.confirmation h3 {
		font-size: 1rem;
	}
}

@media screen and (max-width: 605px) {
	.confirmation h3 {
		font-size: 0.9rem;
	}
}

/*
=============== 
Arreplegator
===============
*/

.arreplegator-wrap {
	margin-top: 2rem;
	display: grid;
	gap: 40px 20px;
	grid-template-columns: repeat(6, 1fr);
}

.arreplegator {
	width: 100%;
	aspect-ratio: calc(1 / 1.41421);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	box-shadow: var(--shadow-5);
}

.link-btn {
	cursor: pointer;
	color: var(--white);
	background: var(--primary-600);
	border: transparent;
	border-radius: var(--borderRadius);
	letter-spacing: var(--letterSpacing);
	padding: 0.375rem 1rem;
	margin: 3rem auto 0;
	width: fit-content;
	box-shadow: var(--shadow-1);
	transition: var(--transition);
	display: block;
}
.link-btn:hover {
	background: var(--primary-700);
	box-shadow: var(--shadow-3);
}

/*
=============== 
Palette
===============
*/

.palette {
	display: grid;
	width: 100%;
	height: 60vh;
	grid-template-rows: repeat(2, 1fr);
}

.palette div {
	height: 100%;
	display: grid;
	grid-template-columns: repeat(9, 1fr);
}

.palette div div {
	height: 100%;
}

/*
=============== 
Log In
===============
*/

.appsistencia-login {
	text-align: center;
}

.appsistencia-login h1 {
	margin: 0 auto 1rem;
	text-transform: uppercase;
}

.appsistencia-login p {
	color: var(--grey-400);
	text-align: left;
	font-size: 0.8rem;
	max-width: 400px;
	margin: 0 auto 0.5rem !important;
}

.appsistencia-login .message {
	max-width: 400px;
	margin: auto;
	margin-bottom: 1rem;
	padding: 10px;
	font-size: 0.9rem;
}
.appsistencia-login .message span::before {
	text-transform: uppercase;
	display: block;
	font-weight: 600;
}
.appsistencia-login .warning {
	background-color: var(--orange-light);
}
.appsistencia-login .warning span::before {
	content: 'Advertència';
	color: var(--orange-dark);
}
.appsistencia-login .error {
	background-color: var(--red-light);
}
.appsistencia-login .error span::before {
	content: 'Error';
	color: var(--red-dark);
}
.appsistencia-login .info {
	background-color: var(--blue-light);
}
.appsistencia-login .info span::before {
	content: 'Informació';
	color: var(--blue-dark);
}

.appsistencia-login form {
	display: flex;
	flex-direction: column;
	max-width: 400px;
	margin: 0 auto;
}

.appsistencia-login form * {
	width: 100%;
}
.appsistencia-login input {
	margin-bottom: 3px;
	height: 2rem;
}
.appsistencia-login button {
	width: fit-content;
	margin: 1rem auto 0;
	padding: 0.5rem 1rem;
}

/*
=============== 
Jocs
===============
*/

.game.btn {
	display: flex;
	justify-content: center;
	align-items: center;
}

.game.btn span {
	width: fit-content;
	height: fit-content;
}

.game-levels {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
	gap: 0.5rem;
	width: 100%;
	user-select: none;
}

.game-levels > * {
	min-width: 6rem;
	text-align: center;
	border: 3px solid var(--primary-600);
}
.game-levels > *:hover {
	border: 3px solid var(--primary-700);
}

.game-levels .incomplete {
	background-color: var(--white);
	color: var(--primary-600);
	font-weight: 400;
}
.game-levels .incomplete:hover {
	border: 3px solid var(--primary-600);
}

.games {
	display: grid;
	gap: 3rem;
	grid-template-columns: repeat(4, 1fr);
	user-select: none;
}

.game {
	text-align: center;
	text-transform: none;
}

@media screen and (max-width: 1000px) {
	.games {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media screen and (max-width: 750px) {
	.games {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 500px) {
	.games {
		grid-template-columns: repeat(1, 1fr);
	}
}

/*
=============== 
Sopa de lletres
===============
*/

.levels {
	display: flex;
	gap: 15px;
	justify-content: center;
	margin-bottom: 1rem;
}

.level {
	width: 1.5rem;
	height: 1.5rem;
	line-height: calc(1.5rem - 4px);
	border-radius: 50%;
	font-size: 0.8rem;
	text-align: center;
	border: 2px solid;
	user-select: none;
}
.level.prev {
	background-color: var(--primary-600);
	color: var(--white);
	border-color: var(--primary-700);
	cursor: pointer;
}
.level.current {
	background-color: var(--white);
	color: var(--primary-600);
}
.level.next {
	background-color: var(--grey-300);
	color: var(--grey-500);
	cursor: not-allowed;
}

.game-two-columns {
	display: grid;
	gap: 2rem;
	grid-template-columns: 3fr 2fr;
}

.square-table {
	border-collapse: collapse;
	user-select: none;
}

.square-table td {
	position: relative;
	width: 2.5rem;
}
.square-table td:after{
	content: '';
	display: block;
	margin-top: 100%;
}

.square-table td .content {
	position: absolute;
	line-height: 2.5rem;
	font-size: 1.5rem;
	width: 100%;
	height: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	text-align: center;
	user-select: none;
	text-transform: uppercase;
	color: var(--black);
}

@media screen and (max-width: 1200px) {
	.square-table td {
		width: 2rem;
	}

	.square-table td .content {
		line-height: 2rem;
		font-size: 1.3rem;
	}
}

@media screen and (max-width: 950px) {
	.square-table td {
		width: 1.5rem;
	}

	.square-table td .content {
		line-height: 1.5rem;
		font-size: 1rem;
	}
}

@media screen and (max-width: 700px) {
	.game-two-columns {
		grid-template-columns: 1fr;
	}
}

.word-search-table td {
	border: 1px solid var(--black);
	cursor: pointer;
}

.selector {
	position: absolute;
	box-sizing: content-box;
	border: 1.25rem solid var(--primary-700);
	border-radius: 99999px;
	opacity: 0;
}
.selector.show {
	opacity: 0.5;
}

.word-search-list {
	text-transform: uppercase;
}

.word-search-list .done {
	text-decoration: line-through;
	text-decoration-thickness: 2px;
	color: var(--grey-400);
}

@media screen and (max-width: 1200px) {
	.selector {
		border: 1rem solid var(--primary-700);
	}
}

@media screen and (max-width: 950px) {
	.selector {
		border: 0.75rem solid var(--primary-700);
	}
}

/*
=============== 
Mots encreuats
===============
*/

.crossword-table {
	display: block;
	border-collapse: separate;
}

.cw-number {
	position: absolute;
	font-size: 0.5rem;
	top: 0;
	left: 3px;
	z-index: 1000;
	color: var(--grey-600);
}

input.content {
	outline: none;
	border-radius: 0;
	padding: 0;
}

/*
=============== 
Contactar
===============
*/

.contact-form {
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
}

label.required::after {
	content: '*';
	color: var(--red-dark);
}

.contact-form input {
	margin-bottom: 0.5rem;
}

.contact-form textarea {
	resize: vertical;
	height: 10rem;
}

.contact-form button {
	margin-top: 1rem;
}

.contact-info a {
	color: var(--arreplegats);

	&:hover {
		text-decoration: underline;
	}
}

/*
=============== 
Castells Universitaris
===============
*/

.timeline {
	--color: rgba(30, 30, 30);
	--bgColor: rgba(240, 240, 240);
	--col-gap: 2rem;
	--row-gap: 2rem;
	--line-w: 0.25rem;
	display: grid;
	grid-template-columns: var(--line-w) 1fr;
	grid-auto-columns: max-content;
	column-gap: var(--col-gap);
	list-style: none;
	width: min(60rem, 90%);
	margin-inline: auto;
	margin-top: 2rem;
}

/* line */
.timeline::before {
	content: "";
	grid-column: 1;
	grid-row: 1 / span 10000;
	background: rgb(225, 225, 225);
	border-radius: calc(var(--line-w) / 2);
}

/* row gaps */
.timeline li:not(:last-child) {
	margin-bottom: var(--row-gap);
}

/* card */
.timeline li {
	grid-column: 2;
	--inlineP: 1.5rem;
	margin-inline: var(--inlineP);
	grid-row: span 2;
	display: grid;
	grid-template-rows: min-content min-content min-content;
}

/* date */
.timeline li .date {
	--dateH: 2.5rem;
	height: var(--dateH);
	margin-inline: calc(var(--inlineP) * -1);
	text-align: center;
	color: var(--white);
	font-size: 1.25rem;
	font-weight: 700;
	display: grid;
	place-content: center;
	position: relative;
	border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
}

/* date flap */
.timeline li .date::before {
	content: "";
	width: var(--inlineP);
	aspect-ratio: 1;
	background: inherit;
	background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, transparent);
	position: absolute;
	top: 100%;
	clip-path: polygon(0 0, 100% 0, 0 100%);
	right: 0;
}

/* circle */
.timeline li .date::after {
	content: "";
	position: absolute;
	width: 2rem;
	aspect-ratio: 1;
	background: var(--bgColor);
	border: 0.3rem solid rgb(143, 143, 143);
	border-radius: 50%;
	top: 50%;
	transform: translate(50%, -50%);
	right: calc(100% + var(--col-gap) + var(--line-w) / 2);
}

/* title descr img */
.timeline li .title,
.timeline li .descr,
.timeline li .img {
	background: var(--bgColor);
	position: relative;
	padding-inline: 1.5rem;
}
.timeline li .title {
	overflow: hidden;
	padding-block-start: 1.5rem;
	padding-block-end: 1rem;
	font-weight: 600;
}
.timeline li .descr {
	padding-block-end: 1.5rem;
	font-weight: 300;
}

.timeline li .descr a {
	color: var(--primary-600);
	transition: var(--transition);
}
.timeline li .descr a:hover {
	color: var(--secondary-600);
	text-decoration: underline;
}

.timeline li .img {
	padding-inline: 3rem;
	padding-block-end: 1rem;
	max-height: 350px;
	object-fit: contain;
}

/* shadows */
.timeline li .title::before,
.timeline li .descr::before {
	content: "";
	position: absolute;
	width: 90%;
	height: 0.5rem;
	background: rgba(0, 0, 0, 0.5);
	left: 50%;
	border-radius: 50%;
	filter: blur(4px);
	transform: translate(-50%, 50%);
}
.timeline li .title::before {
	bottom: calc(100% + 0.125rem);
}

.timeline li .descr::before {
	z-index: -1;
	bottom: 0.25rem;
}

@media (min-width: 40rem) {
	.timeline {
	grid-template-columns: 1fr var(--line-w) 1fr;
	}
	.timeline::before {
		grid-column: 2;
	}
	.timeline li:nth-child(odd) {
		grid-column: 1;
	}
	.timeline li:nth-child(even) {
		grid-column: 3;
	}

	/* start second card */
	.timeline li:nth-child(2) {
		grid-row: 2/4;
	}

	.timeline li:nth-child(odd) .date::before {
		clip-path: polygon(0 0, 100% 0, 100% 100%);
		left: 0;
	}

	.timeline li:nth-child(odd) .date::after {
		transform: translate(-50%, -50%);
		left: calc(100% + var(--col-gap) + var(--line-w) / 2);
	}
	.timeline li:nth-child(odd) .date {
		border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
	}
}

/*
=============== 
Agenda
===============
*/

.calendar {
	width: 800px;
}

#calendar .header {
	margin-bottom: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	user-select: none;
}

#calendar .header span {
	color: var(--grey-500);
}

#calendar .header button {
	width: 2rem;
	height: 2rem;
	background-color: transparent;
	border: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--grey-500);
}
#calendar .header button:hover {
	background-color: var(--grey-200);
}

#calendar table {
	width: 100%;
	text-align: center;
	border-collapse: collapse;
}

#calendar th {
	padding-bottom: 0.3rem;
	background-color: transparent;
	user-select: none;
}

#calendar td {
	height: 110px;
	overflow: hidden;
	position: relative;
	width: calc(100% / 7);
}
#calendar td:not(.blank) {
	border: 1px solid var(--grey-200);
}
#calendar td:hover .day {
	text-decoration: underline;
}

#calendar .day {
	position: absolute;
	top: 0;
	right: 0;
	user-select: none;
	padding: 1px 3px;
	font-size: 0.8rem;
}
#calendar .weekend .day {
	color: var(--barometre);
}
#calendar .today {
	background-color: var(--primary-100);
}

#calendar .event {
	top: 20px;
	width: calc(100% - 4px);
	padding: 0 2px;
	position: absolute;
	left: 0;
	white-space: nowrap;
}
#calendar .event div {
	font-size: 0.8rem;
	padding-left: 5px;
	border-radius: 3px;
	margin-bottom: 2px;
	line-height: 1.5;
	overflow: hidden;
	text-align: left;
	user-select: none;
}
#calendar .event div:hover {
	opacity: 0.8;
	cursor: pointer;
}

#calendar .jump-today {
	margin-top: 1rem;
	width: 100%;
	text-transform: none;
	user-select: none;
}

#calendar .event-info {
	padding: 10px;
	padding-right: 50px;
	max-width: 450px;
	position: absolute;
	top: 30%;
	left: 3%;
	background-color: #ffffff;
	border: 3px solid var(--primary-600);
	box-shadow: var(--shadow-2);
}

#calendar .event-info p {
	margin: 0;
}

#calendar .event-info h2 {
	font-size: 20px;
	margin: 0;
}

#calendar .event-info h4 {
	font-size: 0.7rem;
	margin: 0.5rem 0 1rem;
	padding: 5px 10px 3px;
	border-radius: var(--borderRadius);
	box-shadow: var(--shadow-1);
	width: fit-content;
	user-select: none;
}

#calendar .event-info #event-map {
	margin-bottom: 0.5rem;
}

#calendar .event-info #event-desc {
	margin: 1rem 0 0 1rem;
	font-weight: 700;
	line-height: 1.5;
}

#calendar .event-info #event-map,
#calendar .event-info #event-link {
	margin-left: 2rem;
	font-size: 0.85rem;
	color: var(--primary-600);
	width: fit-content;
	transition: var(--transition);
}
#calendar .event-info #event-map:hover,
#calendar .event-info #event-link:hover {
	color: var(--secondary-600);
}

#calendar .close {
	cursor: pointer;
}
#calendar .close::after {
	width: 20px;
	height: 20px;
	line-height: 23px;
	top: 5px;
	right: 5px;
	content: '×';
	color: var(--white);
	background-color: var(--primary-600);
	border: 2px solid var(--primary-700);
	overflow: hidden;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: var(--transition);
}
#calendar .close:hover::after {
	color: var(--primary-700);
	background-color: var(--white);
}

@media (max-width: 850px) {
	.calendar {
		width: 98%;
	}
	#calendar td {
		height: 80px;
	}
	#calendar .event {
		width: calc(100% - 2px);
		padding: 0 1px;
	}
	#calendar .event div {
		padding: 0 1px;
		font-size: 9px;
		margin-bottom: 1px;
	}
	#calendar .event-info {
		max-width: 75%;
	}
}

/*
=============== 
En construcció
===============
*/

.in-construction {
	user-select: none;
}

.in-construction h2 {
	text-align: center;
	text-transform: uppercase;
	font-size: 2.5rem;
	color: var(--primary-600);
}

.in-construction h4 {
	text-align: center;
	text-transform: uppercase;
	font-size: 1rem;
	color: var(--primary-600);
}

/*
=============== 
Slideshow
===============
*/

.slideshow-container {
	width: 100%;
	margin-bottom: 10px;
	user-select: none;
}

.slideshow {
	width: 100%;
	position: relative;
	overflow: hidden;
}

.prev,
.next {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 8px;
	margin: auto 0 !important;
	height: 35px;
	width: 35px;
	line-height: 35px;
	text-align: center;
	color: white;
	font-size: 18px;
	transition: 0.6s ease;
	border-radius: 50%;
	cursor: pointer;
	display: none;
	background-color: rgba(0, 0, 0, 0.5);
}
.slideshow:hover .prev,
.slideshow:hover .next {
	display: block;
}
.next {
	left: unset;
	right: 8px;
}
.prev:hover,
.next:hover {
	background-color: rgba(0, 0, 0, 0.8);
}

.slide {
	display: none;
}

.slide .counter {
	position: absolute;
	top: 8px;
	left: 8px;
	color: var(--white);
	font-size: 0.7rem;
	display: none;
	background-color: rgba(0, 0, 0, 0.5);
	padding: 2px 10px;
	border-radius: 9999px;
}
.slideshow:hover .counter {
	display: block;
}

.slide .slide-img {
	vertical-align: middle;
	object-fit: cover;
	width: 100%;
	aspect-ratio: 0.75;
}

.slide .caption {
	position: absolute;
	bottom: 0;
	transform: translateY(100%);
	color: var(--white);
	font-size: 1rem;
	padding: 8px 12px;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	text-align: center;
	user-select: text !important;
	transition: var(--transition);
}
.slideshow:hover .caption {
	transform: translateY(0%);
}

.slid-dot-container {
	text-align: center;
	margin-top: 1rem;
}

.dot {
	cursor: pointer;
	height: 10px;
	width: 10px;
	margin: 0 3px;
	color: var(--grey-200);
	background-color: var(--grey-200);
	border-radius: 50%;
	display: inline-block;
	transition: background-color 0.6s ease;
	font-size: 0.7rem;
	line-height: 3.5;
}

.dot.active,
.dot:hover {
	color: var(--grey-500);
	background-color: var(--grey-500);
}

@media screen and (min-width: 900px) {
	.slideshow-container {
		width: 50%;
		max-width: 20vw;
		float: left;
		margin: 0 20px 0 0;
	}
}

/*
=============== 
Memory
===============
*/

.memory-container {
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	gap: 0.5rem;
	user-select: none;
}

.memory-card {
	position: relative;
	max-width: 7rem;
	aspect-ratio: 1;
	cursor: pointer;
}

.memory-card .before,
.memory-card .after {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 3px solid var(--black);
	transition: var(--transition);
	backface-visibility: hidden;
}

.memory-card .before {
	background-color: var(--primary-600);
	font-size: 3rem;
	font-weight: 400;
}
.memory-card .after {
	background-color: var(--orange-dark);
	transform: rotateY(180deg);
}
.memory-card.flipped .before {
	transform: rotateY(180deg);
}
.memory-card.flipped .after {
	transform: rotateY(0deg);
}

.memory-card img {
	max-width: 95%;
	max-height: 95%;
}

.win-popup,
.lose-popup {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: var(--white);
	border: 5px solid var(--primary-600);
	border-radius: 1rem;
	padding: 1rem;
	box-shadow: var(--shadow-7);
	display: none;
	z-index: 1000;
}
.lose-popup {
	border-color: var(--red-medium);
}
.win-popup.show,
.lose-popup.show {
	display: flex;
	flex-direction: column;
}

.win-popup h3,
.lose-popup h3 {
	color: var(--primary-600);
	margin: 0;
}
.lose-popup h3 {
	color: var(--red-medium);
}

.win-popup h5,
.lose-popup h5 {
	margin: 1rem auto 2rem;
	font-weight: 300;
	font-size: 1.2rem;
}

.lose-popup .btn {
	background-color: var(--red-medium);
}
.lose-popup .btn:hover {
	background-color: var(--red-dark);
}

/*
=============== 
Penjat
===============
*/

.penjat-word {
	display: flex;
	gap: 0.1rem;
	margin-bottom: 3rem;
}

.penjat-word > * {
	aspect-ratio: 2/3;
	flex-grow: 1;
	max-width: 4rem;
	user-select: none;
}

.penjat-word .letter {
	border-bottom: 5px solid var(--grey-400);
}
.penjat-word .letter,
.penjat-word .geminada {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	min-width: 20px;
}
.penjat-word .letter span,
.penjat-word .geminada span {
	text-align: center;
	width: 100%;
	font-size: 6cqmin;
	margin: 0 -100vw;
	line-height: 0.8;
}
.penjat-word .geminada span {
	line-height: 1;
}
.penjat-word .space {
	min-width: 15px;
}

.penjat-keyboard {
	display: grid;
	width: fit-content;
	margin: 0 auto;
	gap: 0.3rem;
	grid-template-columns: repeat(5, auto);
}

.penjat-keyboard div {
	width: 4rem;
	padding: 2rem 1rem;
	font-size: 2rem;
	text-align: center;
	aspect-ratio: 1;
	line-height: 0;
	background-color: var(--primary-200);
	transition: var(--transition);
	cursor: pointer;
	user-select: none;
}
.penjat-keyboard div:hover {
	transform: scale(1.2);
	background-color: var(--primary-500);
}

.penjat-keyboard .valid {
	background-color: var(--primary-600) !important;
	color: var(--white);
	font-weight: 500;
}
.penjat-keyboard .invalid {
	background-color: var(--grey-200) !important;
	color: var(--red-dark);
	font-weight: 500;
}
.penjat-keyboard .valid:hover,
.penjat-keyboard .invalid:hover {
	transform: none;
}

@media screen and (max-width: 900px) {
	.penjat-keyboard div {
		width: 2rem;
		padding: 1rem 0.5rem;
		font-size: 1rem;
	}
}

/*
=============== 
Joc Castells
===============
*/

.castells-game {
	user-select: none;
}

.cant-play {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: none;
	background-color: var(--grey-200);
}

.cant-play h2 {
	color: var(--primary-600);
	margin: auto;
	text-align: center;
	min-width: 25rem;
	width: 50%;
}

.flex-page {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
}

.btn-wrap {
	display: flex;
	flex-direction: column;
	margin: auto;
	width: max(30%, 15rem);
}

.btn-wrap * {
	text-align: center;
	line-height: 1.5;
	margin-bottom: 0.5rem;
}
.btn-wrap :last-child {
	margin: 0;
}

.btn-wrap .btn.disabled {
	pointer-events: none;
	background-color: #aaa;
	color: #ccc;
}

.group-wrap.disabled {
	pointer-events: none;
	background-color: #aaa;
	color: #ccc;
}

.boto-netes.disabled {
	pointer-events: none;
    background-color: var(--game-darkGray);
	color: #aaa;
}

.game-error {
	color: var(--red-dark);
}
.game-error:empty {
	display: none;
}

.game-popup {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: var(--white);
	border: 5px solid var(--primary-600);
	border-radius: var(--borderRadius);
	padding: 0.5rem;
	box-shadow: var(--shadow-7);
	display: none;
	z-index: 1000;
}
.game-popup input {
	margin-bottom: 0.5rem;
}
.game-popup button {
	margin-top: 1rem;
}
.game-popup .cancel-btn {
	color: var(--grey-400);
	text-align: center;
	margin: 0.5rem 0 0;
	cursor: pointer;
}
.game-popup .cancel-btn:hover {
	text-decoration: underline;
}

.create-colla {
	flex-direction: column;
}

.top-bar {
	padding: 0 0.5rem;
	height: var(--game-top-bar-height);
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.top-bar span {
	font-size: 1.25rem;
	white-space: nowrap;
	overflow: scroll;
}
.top-bar span::-webkit-scrollbar {
	display: none;
}

.top-bar button {
	box-shadow: none;
	margin-left: 1rem;
}
.top-bar button:hover {
	box-shadow: var(--shadow-7);
}

.sub-bar {
	padding: 0.15rem 0.5rem;
	height: var(--game-sub-bar-height);
	background-color: var(--grey-300);
	line-height: 1;
}

#game-background {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: var(--game-darkBlue);
	z-index: -1;
}

.menu {
	position: absolute;
	top: 30px;
	right: 0;
	left: 0;
	bottom: auto;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	width: min(60rem, 95%);
	margin: auto;
}

.menu .game-menu-buttons {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 10px;
}

.menu .game-menu-buttons button {
	padding-top: 5rem;
	padding-right: 1.1rem;
}

.menu button {
	border-radius: 0;
	background-color: var(--game-mediumBlue);
	border: 1px solid var(--game-lightBlue);
	color: var(--white);
	transition: 0.15s;
	cursor: pointer;
	text-transform: unset;
	text-align: left;
	padding: 0.5rem;
}
.menu button:hover {
	background-color: var(--game-cream);
	color: var(--black);
	transform: scale(1.02);
}
.menu button.disabled {
	pointer-events: none;
	background-color: var(--game-darkGray);
	border-color: var(--game-lightGray);
	color: var(--game-lightGray);
}

.menu .btn span {
	font-style: italic;
}

.menu .btn .btn-subtitle {
	font-size: 12px;
	font-style: italic;
}

.game-proves-left-short {
	display: flex;
	gap: 7px;
}

.game-proves-left-short img {
	width: 12px;
	filter: invert(100%) opacity(100%) !important;
}
.game-proves-left-short .invert img {
	filter: invert(0%) !important;
}

.game-header {
	grid-column: span 3;
	color: var(--white);
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-bottom: 20px;
}

.game-menu-time {
	grid-column: span 3;
	color: var(--white);
}

.game-day-title {
	font-size: 20px;
}

.game-current-day {
	display: flex;
	line-height: 1;
	gap: 5px;
}

.game-current-day img {
	filter: invert(100%) opacity(90%);
}

.game-current-day span {
	padding-top: 2px;
}
.game-current-day span::first-letter {
	text-transform: uppercase;
}

.game-advance-day {
	padding: 0.5rem;
}

.menu button {
	border-radius: 0;
	background-color: var(--game-mediumBlue);
	border: 1px solid var(--game-lightBlue);
	color: var(--white);
	transition: 0.15s;
	cursor: pointer;
	text-transform: unset;
	text-align: left;
}
.menu button:hover {
	background-color: var(--game-cream);
	color: var(--black);
	transform: scale(1.02);
}
.menu button.disabled {
	pointer-events: none;
	background-color: var(--game-darkGray);
	border-color: var(--game-lightGray);
	color: var(--game-lightGray);
}

.menu .btn {
	display: flex;
	padding: 0.5rem;
	align-items: flex-end;
	flex: 1 1 30%;
}

.menu .span-wrap {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.menu .btn span {
	font-style: italic;
}

.back-btn {
	width: calc(100% - 2rem);
	margin: 1rem;
	height: var(--game-back-btn-height);
	background-color: var(--game-lightBlue);
	border: none;
	color: var(--white);
	text-align: center;
	transition: 0.15s;
	cursor: pointer;
	opacity: 0.75;
}
.back-btn:hover {
	opacity: 1;
	box-shadow: 0 0 5px var(--game-lightBlue);
}

.game-table-wrap {
	display: flex;
	margin: 0 1rem;
	overflow: scroll;
	height: 80vh;
	padding-bottom: 50px;
}
.game-table-wrap::-webkit-scrollbar {
	display: none;
}

.score-table {
	color: var(--white);
	border-collapse: collapse;
	height: fit-content;
	margin: 0;
	margin-right: 1rem;
}
.score-table:last-of-type {
	margin: 0;
}

tr.locked {
	opacity: 0.3;
}

.score-table th,
.score-table td {
	line-height: 1.1;
	padding: 5px 7px;
	border: 1px solid var(--game-lightBlue);
}
.score-table th {
	background-color: var(--game-lightBlue);
}
.score-table td {
	background-color: var(--game-mediumBlue);
	white-space: nowrap;
}

span.help {
	display: inline-block;
	cursor: pointer;
	border-radius: 50%;
	width: 1rem;
	height: 1rem;
	line-height: 1.2;
	margin-left: 0.5rem;
	font-size: 0.85rem;
	text-align: center;
	background-color: var(--game-lightBlue);
}

.game-full-wrap {
	position: relative;
	display: flex;
	flex-direction: column;
	height: calc(100vh - var(--game-top-bar-height) - var(--game-sub-bar-height) - var(--game-back-btn-height) - 2rem);
	overflow: auto;
    padding-bottom: 100px;
}
.game-bigger-wrap {
	height: calc(100vh - var(--game-top-bar-height) - var(--game-sub-bar-height));
}

.game-castell-selector {
	position: relative;
	margin: 2rem auto;
	background-color: var(--game-clearBlue);
	border: 3px solid var(--game-lightBlue);
	width: min(500px, 95%);
	padding: 8px;
	display: flex;
	gap: 3px;
	flex-direction: column;
}

.game-castell-selector h4 {
	text-align: center;
	margin-bottom: 0;
}

.game-castell-selector-full-height {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.game-castell-selector-full-height button {
	width: fit-content !important;
	padding-right: 20px !important;
	padding-left: 20px !important;
}

.game-castell-selector .box-wrap {
	gap: 3px;
	display: flex;
	flex-wrap: wrap;
	flex: 1;
	justify-content: center;
}

.game-castell-selector .group-wrap {
	flex: 1 0 100px;
	min-height: 100px;
}

.game-castell-selector .box-wrap div {
	background-color: var(--game-lightBlue);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	opacity: 0.75;
}
.game-castell-selector .box-wrap div:hover {
	transition: var(--transition);
	opacity: 1;
}
.game-castell-selector .box-wrap div.disabled {
	pointer-events: none;
	background-color: var(--game-darkGray);
}

.game-castell-selector .castell {
	color: var(--white);
}

.game-selector-single {
	display: flex;
	flex-direction: column;
	gap: 10px;
	flex: 1;
	flex-basis: 100px;
	min-height: 100px;
}

.game-selector-single span {
	line-height: 0.7;
}
.game-selector-single.disabled span {
	color: var(--game-lightGray);
}

.game-selector-single .gent {
	font-size: 0.6rem;
	height: fit-content;
}

.game-castell-difficulty-wrap {
	display: flex;
	gap: 2px;
	opacity: 1 !important;
}

.game-castell-difficulty-wrap div {
	width: 15px;
	height: 4px;
	opacity: 1 !important;
}

.game-castell-selector .back-btn {
	margin: 0.25rem 0 0;
	width: 100%;
}
.game-castell-selector .back-btn:hover {
	box-shadow: none;
}

.game-proves-left {
	position: absolute;
	width: 100%;
	height: 2rem;
	bottom: -3rem;
	left: 0;
	background-color: var(--game-clearBlue);
	border: 3px solid var(--game-lightBlue);
	text-align: center;
	font-weight: 500;
}
.game-last-prova {
	background-color: var(--red-medium);
	border-color: var(--red-dark);
	font-weight: 600;
}

.game-proves-extra {
	display: flex;
	gap: 3px;
}

.game-proves-extra div {
	width: 100%;
	padding: 0.3rem 0;
	background-color: var(--game-lightBlue);
	color: var(--white);
	text-align: center;
	transition: var(--transition);
	cursor: pointer;
	opacity: 0.75;
}
.game-proves-extra div:hover {
	opacity: 1;
}

.game-canvas-center {
	position: absolute;
	margin: auto;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: fit-content;
	height: fit-content;
	color: var(--white);
	text-align: center;
}

.game-canvas-center h1,
.game-canvas-center h5 {
	margin: 0;
}

.game-canvas-center .carregat {
	color: var(--game-carregat);
}
.game-canvas-center .intent {
	color: var(--red-dark);
}
.game-canvas-center .desmuntat {
	color: var(--red-medium);
}

.game-canvas-center .game-castell-history {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	gap: 5px;
	margin: 10px 0;
}

.game-canvas-center .game-castell-history span {
	width: 20px;
	border-radius: 5px;
	background-color: var(--white);
	color: var(--black);
	line-height: 1.5;
}
.game-canvas-center .game-castell-history span.c {
	background-color: var(--game-carregat);
}
.game-canvas-center .game-castell-history span.i {
	background-color: var(--red-dark);
	color: var(--white);
}
.game-canvas-center .game-castell-history span.id {
	background-color: var(--red-medium);
}

.game-canvas-center .back-btn {
	margin-top: 2rem;
}

.game-actuacio-result {
	margin: 0 auto;
	width: min(300px, 95%);
	height: fit-content;
	padding: 30px;
	background-color: var(--game-clearBlue);
	border: 3px solid var(--game-lightBlue);
}

.game-actuacio-result h4 {
	color: var(--game-lightBlue);
	margin-bottom: 0;
}

.game-actuacio-result h4 span {
	font-size: 1rem;
}

.game-actuacio-result p span {
	font-size: 0.8rem;
	font-style: italic;
}

.game-actuacio-result h4 span,
.game-actuacio-result p span {
	margin-left: 1rem;
}
.game-actuacio-result h4 span::before,
.game-actuacio-result p span::before {
	content: '(';
}
.game-actuacio-result h4 span::after,
.game-actuacio-result p span::after {
	content: ')';
}

.game-actuacio-result h3 {
	margin: 0;
}

.game-actuacio-result .back-btn {
	margin: 2rem auto 0;
	width: 100%;
}

.game-historic-table {
	color: var(--white);
	border-collapse: collapse;
	width: 100%;
	overflow: unset;
	height: fit-content;
}

.game-historic-table th,
.game-historic-table td {
	padding: 1px 5px;
	border: 1px solid var(--game-lightBlue);
}
.game-historic-table th {
	background-color: var(--game-lightBlue);
}
.game-historic-table td {
	background-color: var(--game-mediumBlue);
}
.game-historic-table td:nth-child(1),
.game-historic-table td:nth-child(2),
.game-historic-table td:nth-child(4) {
	white-space: nowrap;
}

.game-stats {
	flex-direction: column;
	padding: 0 1rem 2rem;
}

.game-stats h3 {
	text-align: center;
	color: var(--white);
}

.game-stats-wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 1rem;
	overflow-y: scroll;
}

.game-stats-single-graphic {
	max-width: 300px;
	padding: 16px;
	background-color: var(--game-darkerBlue);
}

.game-stats-single-graphic h4 {
	color: var(--white);
}

.game-stats-single-graphic canvas {
	width: 100% !important;
}

.game-missions {
	flex-direction: column;
	padding: 0 1rem 2rem;
}

.game-missions h3 {
	text-align: center;
	color: var(--white);
}

.game-missions-wrap {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
	overflow-y: scroll;
	margin-bottom: 2rem;
}

.game-missions-wrap .game-mission {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	background-color: var(--game-clearBlue);
	border: 3px solid var(--game-lightBlue);
	padding: 0.5rem;
}

.game-missions-wrap .btn {
	text-transform: unset;
	height: fit-content;
	white-space: nowrap;
	background-color: var(--game-lightBlue);
}

.game-missions-wrap .game-mission h5 {
	text-transform: uppercase;
	margin: 0;
	margin-bottom: 0.5rem;
}

.game-missions-wrap .game-mission p {
	margin: 0;
}

.game-mission-progress h6 {
	margin: 1rem 0 0;
	font-weight: 500;
	font-size: 0.8rem;
}

.game-mission-progress ul {
	padding-left: 2rem;
	list-style-type: disc;
}

.game-help {
	flex-direction: column;
	padding: 0 1rem 2rem;
	max-width: 800px;
	margin: auto;
}

.game-help h3 {
	text-align: center;
	color: var(--white);
}

.game-help p {
	color: var(--white);
}

.game-delete-game,
.game-export-game {
	width: fit-content;
	margin: 1rem auto 0;
	border-radius: 0;
	background-color: var(--game-lightBlue);
	transition: none;
	opacity: 0.85;
}
.game-delete-game:hover {
	background-color: var(--red-dark);
	opacity: 1;
}
.game-export-game:hover {
	background-color: var(--green-dark);
	opacity: 1;
}

@media screen and (max-width: 0px) {
	.castells-game {
		display: none;
	}

	.cant-play {
		display: flex;
	}
}

/*
=============== 
Animations
===============
*/

div.person-animation {
	height: 102px;
	width: 85px;
	animation: playX 0.15s steps(6) infinite, playY 0.75s steps(5) infinite;
}

@keyframes playX {
	from {background-position-x: 0px;}
	to {background-position-x: -512px;}
}

@keyframes playY {
	from {background-position-y: 0px;}
	to {background-position-y: -512px;}
}

/*
=============== 
Patrocinadors
===============
*/

.sponsors-title {
	text-transform: uppercase;
	color: var(--grey-400);
	user-select: none;
}

.sponsors-wrap {
	display: grid;
	gap: 3rem 5%;
	grid-template-columns: repeat(4, 1fr);
	user-select: none;
	padding: 0 5%;
	margin-bottom: 3rem;
}

.sponsor {
	height: 7rem;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

@media screen and (max-width: 500px) {
	.sponsors-wrap {
		grid-template-columns: repeat(3, 1fr);
	}
}

/*
=============== 
Fullscreen PDF
===============
*/

.fullscreen-pdf {
	width: 100%;
	height: calc(100vh - var(--navbar-height) - var(--footer-height) - 2rem);
}

/*
=============== 
Música
===============
*/

.music-iframe-wrap {
	margin: 1rem 0 2rem;
}

.partitures-list {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.partitures-list li {
	background-color: var(--primary-600);
	border-radius: var(--borderRadius);
	padding: 5px 15px;
	width: fit-content;
	cursor: pointer;
}
.partitures-list li:hover {
	background-color: var(--primary-700);
	box-shadow: var(--shadow-8);
}

.partitures-list a {
	color: var(--white);
}

/*
===============
Legal
===============
*/

.legal-title {
	margin-bottom: 1rem;
}

.legal-doc {
	margin-top: 5rem;
}

.legal-doc h3 {
	margin-top: 2rem;
}

.legal-doc h4 {
	margin-top: 1.5rem;
}

.text-bold {
	font-weight: 600;
}

/*
=============== 
Una nit de maig
===============
*/

.nit-fresca-per-ser-maig-title {
	margin-top: 0.5rem;
	text-decoration: underline;
}

.nit-fresca-per-ser-maig-btn {
	margin: 1rem 0;
	text-align: center;
}

.nit-fresca-per-ser-maig-btn button {
	padding: 0.5rem 1rem;
	font-size: 1.25rem;
}

.options-wrap {
	margin: 2rem 0;
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	opacity: 0;
    transition: opacity 0.5s ease-in-out;

	&.show-options {
		opacity: 1;
	}
}

.single-option {
	display: block;
	text-align: center;
}

.options-wrap div {
	text-transform: initial;
}

@media screen and (max-width: 500px) {
	.readable-text {
		font-size: 14px;
	}

	.options-wrap div {
		font-size: 14px;
	}
}

.single-option div {
	margin: 0 auto;
}

.final-h5 {
	margin-top: 3rem;
	font-size: 1.2rem;
	margin-bottom: 0;
}

.final-extra {
	font-size: 0.8rem;
	font-style: italic;
	opacity: 0.5;
}

.final-btn {
	text-align: center;
	margin-top: 1.5rem;

	& .btn {
		padding: 0.75rem 1.5rem;
		font-size: 1.1rem;
		text-transform: unset;
	}
}

/*
===============

===============
*/
