.resized-img {
	display: none;
}

@media screen and (max-width: 576px) {
	.img-576 {
		display: block;
	}
}

@media screen and (min-width: 577px) and (max-width: 768px) {
	.img-768 {
		display: block;
	}
}

@media screen and (min-width: 769px) and (max-width: 992px) {
	.img-992 {
		display: block;
	}
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
	.img-1200 {
		display: block;
	}
}

@media screen and (min-width: 1201px) and (max-width: 1920px) {
	.img-1920 {
		display: block;
	}
}

@media screen and (min-width: 1921px) {
	.img-max {
		display: block;
	}
}
